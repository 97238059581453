<template>
  <div class="dynamic">
    <Filters
      class="content-container"
      :hide-dealer="hideDealer"
      @change="onFilterChange"
    />
    <DealersLine
      ref="grid"
      class="element content-container"
      v-if="isLineShow"
    />
    <DealersSum class="element" v-if="isSumShow" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import Filters from "@/components/reports/dealers/Filters"
import DealersLine from "@/components/reports/dealers/DealersLine"
import DealersSum from "@/components/reports/dealers/DealersSum"

export default {
  name: "Dynamic",
  props: {
    hideDealer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Filters,
    DealersLine,
    DealersSum,
  },
  computed: mapGetters({
    isLineShow: "reportDealers/isLineShow",
    isSumShow: "reportDealers/isSumShow",
  }),

  methods: {
    onFilterChange() {
      this.$refs.grid.refreshData()
    },
  },
}
</script>

<style lang="scss" scoped>
.period {
  .element {
    margin-bottom: 80px;
  }
}
</style>
