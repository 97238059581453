<template>
  <div class="dynamic-line">
    <a-table
      v-if="isDealer || isBuyerForDealers"
      bordered
      class="ordertable"
      :scroll="{x:900}"
      :columns="columnsForAdmin"
      :data-source="lineData"
      rowKey="id"
      :pagination="false"
      @change="handleChangedSort"
      :rowClassName="getClassName"
    >
      <span slot="priceWithNDS" slot-scope="item">
        <template>{{ item | formatPrice }}</template>
      </span>
      <span slot="date" slot-scope="item">
        <template>{{ item | date }}</template>
      </span>
    </a-table>
    <a-table
      v-if="isOwner"
      bordered
      class="ordertable"
      :scroll="{x:900}"
      :columns="columnsForOwner"
      :data-source="lineData"
      rowKey="id"
      :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
      @change="handleChangedSort"
      :rowClassName="getClassName"
    >
      <span slot="priceWithNDS" slot-scope="item">
        <template>{{ item | formatPrice }}</template>
      </span>
      <span slot="date" slot-scope="item">
        <template>{{ item | date }}</template>
      </span>
    </a-table>
    <a-table
      v-if="isBuyer && !isBuyerForDealers"
      bordered
      class="ordertable"
      :scroll="{x:900}"
      :columns="columns"
      :data-source="lineData"
      rowKey="id"
      :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
      @change="handleChangedSort"
      :rowClassName="getClassName"
    >
      <span slot="priceWithNDS" slot-scope="item">
        <template>{{ item | formatPrice }}</template>
      </span>
      <span slot="date" slot-scope="item">
        <template>{{ item | date }}</template>
      </span>
    </a-table>

    <a-pagination
      class="pagination"
      v-model="currentPage"
      :page-size="paginationOptions.pageSize"
      :total="paginationOptions.totalCount"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import moment from "moment"

export default {
  name: "DynamicLine",
  components: {},
  data() {
    return {}
  },
  mounted() {
    this.refreshData()
  },
  computed: {
    ...mapGetters({
      columns: "reportDealers/lineColumns",
      columnsForAdmin: "reportDealers/lineColumnsForAdmin",
      columnsForOwner: "reportDealers/lineColumnsForOwner",
      isDealer: "auth/isDealer",
      isBuyerForDealers: "auth/isBuyerForDealers",
      isOwner: "auth/isOwner",
      isBuyer: "auth/isBuyer",
      lineData: "reportDealers/lineData",
      paginationOptions: "reportDealers/paginationOptions",
    }),

    currentPage: {
      get() {
        return this.paginationOptions.currentPage
      },
      set(page) {
        this.setCurrentPage(page)
      },
    },
  },
  filters: {
    date(value) {
      return moment(value).format("DD.MM.YYYY")
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
      })
      return formatter.format(parseFloat(price))
    },
  },
  methods: {
    ...mapMutations({
      setCurrentPage: "reportDealers/SET_CURRENT_PAGE",
      setSortOptions: "reportDealers/SET_SORT_OPTIONS",
    }),
    ...mapActions({
      fetchLineData: "reportDealers/fetchLineData",
    }),
    refreshData() {
      this.fetchLineData()
    },
    handleChangedSort(pag, filters, sorter){
      this.setSortOptions(sorter)
      this.fetchLineData(sorter)
    },
    getClassName(record){
      return record.className
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    async currentPage(page) {
      await this.refreshData(true)
    },
  },
}
</script>

<style lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
tr.green{
  background-color: lightgreen;
}
tr.yellow{
  background-color: yellow;
}
tr.orange{
  background-color: orange;
}
tr.red{
  background-color: red;
}
</style>
