<template>
  <a-form
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    class="ant-advanced-search-form"
    name="advanced_search"
  >
    <div class="filter-and-show">
      <div class="filter-fields">
        <h3>Фильтр</h3>
        <a-form-item
          v-if="!isBuyer || isBuyerForDealers"
          label="Код дилера"
          class="filter-form-items"
        >
          <a-select
            :value="clientId"
            :show-search="true"
            option-filter-prop="children"
            placeholder="Код дилера"
            class="reg-select selects"
            @change="value => updateClientId(value)"
            style="width: 400px"
          >
            <a-select-option :value="-1">Все</a-select-option>
            <a-select-option
              v-for="item in isBuyerForDealers
                ? [...dcClientsList, user.client]
                : clientsList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.code ? item.code + " - " + item.name : item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Статус" class="filter-form-items">
          <a-select
            :value="statusId"
            placeholder="Статус"
            class="reg-select selects"
            @change="value => updateStatusId(value)"
          >
            <a-select-option :value="-1">Все</a-select-option>
            <a-select-option
              v-for="item in orderStatuses"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Бренд" class="filter-form-items">
          <a-select
            :value="brand"
            placeholder="Бренд"
            class="reg-select selects"
            @change="value => updateBrand(value)"
          >
            <a-select-option value="">Все</a-select-option>
            <a-select-option
              v-for="item in brandsList"
              :key="item.name"
              :value="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          v-if="!isOwner"
          label="Поставщик"
          class="filter-form-items"
        >
          <a-select
            :value="companyId"
            placeholder="Поставщик"
            class="reg-select selects"
            @change="value => updateCompanyId(value)"
          >
            <a-select-option :value="-1">Все</a-select-option>
            <a-select-option
              v-for="item in companiesList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Период" class="filter-form-items">
          <a-range-picker
            class="reg-select selects"
            :value="period"
            @change="(value, dates) => updatePeriod(dates)"
          />
        </a-form-item>
      </div>
    </div>
    <div class="buttons">
      <a-button type="primary" @click="onFilterClick">Фильтр</a-button>
      <a-button @click="onFilterClearClick">Очистить</a-button>
      <a-button @click="exportExcel">Выгрузить в Excel</a-button>
    </div>
  </a-form>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex"
import api from "@/utils/api"

export default {
  name: "Filters",
  props: {
    hideDealer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "advanced_search" }),
      labelCol: { span: 3 },
      wrapperCol: { span: 14 },
    }
  },
  mounted() {
    this.fetchCompaniesList()
    //this.fetchDealersList()
    this.fetchClientsList()
    this.fetchBrandsList()
    this.getOrderStatuses()
  },
  computed: mapGetters({
    user: "auth/user",
    isBuyer: "auth/isBuyer",
    isBuyerForDealers: "auth/isBuyerForDealers",
    isOwner: "auth/isOwner",
    //isLineShow: "reportDealers/isTempLineShow",
    //isSumShow: "reportDealers/isTempSumShow",
    clientId: "reportDealers/clientId",
    companyId: "reportDealers/companyId",
    statusId: "reportDealers/statusId",
    brand: "reportDealers/brand",
    period: "reportDealers/period",
    companiesList: "clients/companiesList",
    dealersList: "clients/dealersList",
    clientsList: "clients/clientsList",
    dcClientsList: "auth/dcClientCodes",
    brandsList: "clients/brandsList",
    orderStatuses: "orders/orderStatuses",
  }),
  methods: {
    ...mapMutations({
      // updateFilterIsLineShow: "reportDealers/UPDATE_FILTER_IS_LINE_SHOW",
      // updateFilterIsSumShow: "reportDealers/UPDATE_FILTER_IS_SUM_SHOW",
      updateClientId: "reportDealers/UPDATE_FILTER_CLIENT_ID",
      updateCompanyId: "reportDealers/UPDATE_FILTER_COMPANY_ID",
      updateStatusId: "reportDealers/UPDATE_FILTER_STATUS_ID",
      updateBrand: "reportDealers/UPDATE_FILTER_BRAND",
      updatePeriod: "reportDealers/UPDATE_FILTER_PERIOD",
      filter: "reportDealers/FILTER",
      clearFilters: "reportDealers/CLEAR_FILTERS",
    }),
    ...mapActions({
      fetchCompaniesList: "clients/fetchCompaniesList",
      fetchDealersList: "clients/fetchDealersList",
      fetchClientsList: "clients/fetchClientsList",
      fetchBrandsList: "clients/fetchBrandsList",
      getOrderStatuses: "orders/getOrderStatuses",
    }),
    onFilterClick() {
      this.filter()
      this.$emit("change")
    },
    onFilterClearClick() {
      this.clearFilters()
      this.$emit("change")
    },
    exportExcel() {
      const params = {
        filters: {
          brand: this.brand,
          company_id: this.companyId,
          client_id: this.clientId,
          status_id: this.statusId,
          period: this.period,
        },
      }
      api.downloadFileFromPost(`/reports/export_excel`, params)
    },
  },
}
</script>

<style lang="scss" scoped>
/* TODO: change styles for filter components */
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}

#advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}

.search-result-text {
  margin: 20px 0 10px 0;
}

.filter-and-show {
  display: flex;
  flex-direction: row;
}

.filter-fields {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.filter-form-items {
  display: block;
  margin-right: 20px;
  margin-bottom: 10px !important;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
  > button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}

.dealer-select {
  margin-left: 40px;
}
.reg-select {
  margin-left: 36px;
}
.brand-select {
  margin-left: 40px;
}
.manager-select {
  margin-left: 12px;
}
.period-select {
  margin-left: 31px;
}

.selects {
  width: 200px;
}

.lines-select {
  margin-left: 20px;
}
.amounts-select {
  margin-left: 14px;
}
</style>
